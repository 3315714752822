<template>
  <Header />
  <Suspense>
    <template #default>
       <Healths />
    </template>
    <template #fallback>
       <Loding />
    </template>
  </Suspense>
  <Footer />
</template>

<script>
import { Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import Header from "@/components/Include/Header.vue";
import Footer from "@/components/Include/Footer.vue";
import Healths from "@/components/Categorys/Healths.vue";
export default {
  name: 'Health',
   components: {
    Suspense,
    Loding,
    Header,
    Footer,
    Healths
  },
}
</script>

<style>

</style>